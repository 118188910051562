import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { selectDateRange, selectFilters } from '../../store/slices/filters';
import { AdditionalField } from '../../services/comments';
import { DateRange } from '../../utils';
import { DataListProps } from '../../screens/analytics/widgets/data-report';
import { GetDataListOptions, getDataListReport } from '../../services/reports';

export interface UseDataListConfig {
  dateRangeField: string;
  mainField: string;
  valueMainField: string;
  additionalFields?: AdditionalField[];
  display?: string
}

type UseDataListHook = (config: DataListProps['query']) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  Array<any> | undefined
];

const useReportDataList: UseDataListHook = ({
  dateRangeField,
  mainField,
  valueMainField,
  additionalFields,
  baseFilters = [],
  display,
}) => {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<Array<any> | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const hasBaseFilters = baseFilters.length;

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        const query: GetDataListOptions = {
          studyId: studies.selected._id,
          range: dateRange as DateRange,
          dateRangeField,
          mainField,
          valueMainField,
          additionalFields,
          pagination: {
            sort: { createdAt: -1 }
          }
        }
        if (hasBaseFilters) {
          query.filters = baseFilters
        }
        if (display) {
          query.display = display
        }
        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters]
          } else {
            query.filters = standarizedFilters
          }
        }
        let analyticsResponse = await getDataListReport(query);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult
  ]

}

export default useReportDataList;