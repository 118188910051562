import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { SimpleViewConfig, ViewConfig } from './views.models';

export interface ViewConfigOptions {
  studyId?: string;
  viewName: string;
}

// Lista todas las vistas disponibles
async function listViews (): Promise<SimpleViewConfig[]> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/view-config/simple?sort=${JSON.stringify({ relevance: 1 })}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<SimpleViewConfig[]> = await fetchResponse.json();
  const viewList = response.data;
  if (!viewList) throw new Error('No view config found')

  return viewList;
}

// Consulta de config. de vista
async function getViewConfig ({
  studyId,
  viewName,
}: ViewConfigOptions): Promise<ViewConfig> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/view-config?conditions=${JSON.stringify({ studyId, viewName })}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<ViewConfig[]> = await fetchResponse.json();
  const viewConfig = response.data.shift();
  if (!viewConfig) throw new Error('No view config found')

  return viewConfig;
}

export {
  getViewConfig,
  listViews,
}