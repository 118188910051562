// Servicio para autenticar al usuario
import { CognitoUserPool, ICognitoUserPoolData, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { removeData } from './local-storage.service';
import { constants } from '../utils'
import { storage } from '.';

interface AuthResult {
  needsNewPass?: boolean;
  loginSuccess?: boolean;
  session?: CognitoUserSession;
  context?: any;
}

const poolData: ICognitoUserPoolData = {
  UserPoolId: 'us-east-2_YjM35RJuO',
  ClientId: '6uj87aj2h2hsuj9u3od39l1lkm',
}

let user: CognitoUser;

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

const login = async (email: string, password: string): Promise<AuthResult> => {
  
  user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const auth = (): Promise<AuthResult> => new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: session => { 
        console.log('Session: ', session);
        resolve({ loginSuccess: true, session }); 
      },
      onFailure: err => { reject(err); },
      newPasswordRequired: context => { resolve({ needsNewPass: true, context }); },
    });
  });

  return await auth();
}

const logout = (): void => {
  removeData(constants.AUTH_USERNAME_KEY);
  removeData(constants.AUTH_USER_TOKEN_KEY);
  removeData(constants.AUTH_REFRESH_TOKEN_KEY);
}

const refreshCognitoSession = async (): Promise<any> => {
  const username = storage.getData(constants.AUTH_USERNAME_KEY);
  const refreshToken = storage.getData(constants.AUTH_REFRESH_TOKEN_KEY);
  
  if (!refreshToken || !username) {
    logout();
    throw new Error('No se pudo actualizar la sesión');
  }

  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const refresh = (): Promise<any> => new Promise((resolve, reject) => {
    user.refreshSession(
      new CognitoRefreshToken({ RefreshToken: refreshToken }),
      (err, session: CognitoUserSession) => {

        if (err) reject(err);

        const token = session?.getIdToken().getJwtToken();
        const refreshToken = session?.getRefreshToken()?.getToken();
        storage.saveData(constants.AUTH_USER_TOKEN_KEY, token);
        storage.saveData(constants.AUTH_REFRESH_TOKEN_KEY, refreshToken);
        resolve(true);
      }
    );
  });

  return await refresh();
}

export {
  login,
  logout,
  refreshCognitoSession,
  user,
}