import React, { FC } from 'react';
import { CenteredLayout } from '../../common/layouts';
import { FileUploadForm } from './widgets';

interface NewImportScreenProps {
};

const NewImportScreen: FC<NewImportScreenProps> = (
  
) => {

  return (
    <CenteredLayout
      title="Cargar archivo para envío"
      navbarProps={{ showControls: true, showMenu: true }}
    >
      <FileUploadForm />
    </CenteredLayout>
  );
}

export default NewImportScreen;
export {
  NewImportScreen,
};