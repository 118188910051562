import React, { FC } from 'react';

const CloseIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.692" height="8.691" viewBox="0 0 8.692 8.691">
    <path id="close" d="M5.135,4.785,8.423,8.074l-.35.35L4.785,5.135,1.5,8.424l-.35-.35L4.435,4.785,1.146,1.5l.35-.35L4.785,4.436,8.074,1.147l.35.35Z" transform="translate(-0.439 -0.44)" fill="currentColor" stroke="currentColor" strokeWidth="1"/>
  </svg>
);

export {
  CloseIcon
}