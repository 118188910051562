//import { ServerResponse } from "../../utils";

export enum SupportedLanguages {
  default = 'default',
  es = 'es'
}

export type LocalizedText = {
  [key in SupportedLanguages]: string;
};

export interface FilterChoice {
  value: string;
  display: LocalizedText;
}

export interface Field {
  _id: string;
  dataType: string;
  fieldName: string; // Se usa en conjunto con prefix para filtrar el ResultView
}

export interface Set {
  _id: string;
  mapperId: string;
  fieldId: Field;
}

export interface RemoteFilter {
  _id: string;
  setId: string;
  name: string; // Nombre a mostrar en el filtro
  prefix: string;
  choices: FilterChoice[];
}

export enum TimeUnit {
  day = 'day',
  week = 'isoWeek',
  month = 'month',
}