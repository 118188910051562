import React, { FC } from 'react';

const TrashIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.995" height="16" viewBox="0 0 12.995 16">
    <g id="delete" transform="translate(0.003 0.001)" fill="currentColor">
      <path d="M222.773,154.7a.375.375,0,0,0-.375.375v7.082a.375.375,0,0,0,.749,0v-7.082A.375.375,0,0,0,222.773,154.7Zm0,0" transform="translate(-214.068 -148.908)"/>
      <path d="M104.773,154.7a.375.375,0,0,0-.375.375v7.082a.375.375,0,0,0,.749,0v-7.082A.375.375,0,0,0,104.773,154.7Zm0,0" transform="translate(-100.49 -148.908)"/>
      <path d="M1.061,4.762v9.232a2.067,2.067,0,0,0,.55,1.426A1.845,1.845,0,0,0,2.949,16h7.09a1.845,1.845,0,0,0,1.339-.579,2.067,2.067,0,0,0,.55-1.426V4.762a1.431,1.431,0,0,0-.367-2.815H9.642V1.479A1.472,1.472,0,0,0,8.158,0H4.831a1.472,1.472,0,0,0-1.484,1.48v.468H1.428a1.431,1.431,0,0,0-.367,2.815Zm8.978,10.487H2.949A1.187,1.187,0,0,1,1.81,13.994v-9.2h9.368v9.2A1.187,1.187,0,0,1,10.039,15.249ZM4.1,1.479A.722.722,0,0,1,4.831.748H8.158a.722.722,0,0,1,.734.731v.468H4.1ZM1.428,2.7H11.56a.674.674,0,0,1,0,1.349H1.428a.674.674,0,0,1,0-1.349Zm0,0" transform="translate(0 0)"/>
      <path d="M163.773,154.7a.375.375,0,0,0-.375.375v7.082a.375.375,0,0,0,.749,0v-7.082A.375.375,0,0,0,163.773,154.7Zm0,0" transform="translate(-157.279 -148.908)"/>
    </g>
  </svg>
);

export {
  TrashIcon,
}