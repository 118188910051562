import React from 'react';

export interface Option<T> {
  label: string;
  value: T;
}

export interface ViewSelectorProps<T> {
  label: string;
  options: Array<Option<T>>;
  selectedValue: T;
  onChange: (value: T) => void;
  disabled?: boolean;
}

export function ViewSelector<T>({
  label,
  options,
  selectedValue,
  onChange,
  disabled,
}: React.PropsWithChildren<ViewSelectorProps<T>>): JSX.Element {
  return (
    <div className="standalone-selector">
      <div className="global-selector-label">{label}</div>
      <div className="global-selector-options">
        {options.map(({ label, value }) => {
          let classes = value === selectedValue ? 'global-selector-item active' : 'global-selector-item';
          if (disabled) {
            classes += ' disabled'
          }
          return (
            <div className={classes} key={String(value)} onClick={() => !disabled && onChange(value)}>
              <div className="global-selector-item-dot"></div>
              {`${label}`}
            </div>
          );
        })}
      </div>
    </div>
  )
};

export default ViewSelector;