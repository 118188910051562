import { useEffect } from 'react'
import { CalcParams } from '../../../services/reports'
import { LocalizedText } from '../../../services/filters'
import './kpi-summary.w.css'
import { getColorByKey } from '../../../utils'
import { ResponsiveContainer, Bar, CartesianGrid,BarChart, Tooltip, XAxis, YAxis, Text, Cell, PieChart, Pie, Legend } from 'recharts'
import { CardMessage } from '../../../common/components/widget-card-message.w'
import { ReportConfig } from '../../../services/views'
import useKpiSummary from '../../../common/hooks/use-kpi-summary'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Tooltip as TooltipTable } from '@material-ui/core'

export type KpiCountConfig = ReportConfig<
  {
    dateRangeField: string,
    field: string,
    calc: CalcParams
  },
  {
    chartType: string;
    resultDisplay: LocalizedText,
    compareWithPreviosPeriod: boolean,
    showTable?: boolean
  }
>

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percentage }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${percentage}%`}
    </text>
  );
};

const YAxisLeftTick = ({ y, payload: { value } }:any) => {
  return (
    <Text x={24} y={y} textAnchor="start" verticalAnchor="middle" fontSize={14} width={200}>
      {value}
    </Text>
  );
};

const makeGetDisplay = (field: string, language: string): GridColDef['valueGetter'] => {
  return (params: GridValueGetterParams) => {
    return params.row.display?.[field]?.[language] ?? params.row.distinctId
  }
}

const KpiCount = ({
  query,
  options: {
    chartType,
    resultDisplay,
    compareWithPreviosPeriod,
    showTable
  },
}: KpiCountConfig) => {
  const [loadReport, loading, error, KpiCount] = useKpiSummary((Object.assign(query, {compareWithPreviosPeriod: compareWithPreviosPeriod !== undefined ? compareWithPreviosPeriod : true })));

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  if (loading) return <CardMessage />
  if (error || !KpiCount || KpiCount?.report?.detail.length <= 0) return (
    <CardMessage message={{ default: 'Error on report', es: `Error al cargar reporte: No existen datos de la fecha consultada` }} />
  )

  const chartData = KpiCount.report.detail.slice().sort((p1, p2) => (p1.n < p2.n) ? 1 : (p1.n > p2.n) ? -1 : 0);
  
  const cols: GridColDef[] = [
    {
      field: 'distinctId',
      type: 'string',
      headerName: 'Opciones',
      disableColumnMenu: true,
      flex: 0.6,
      valueGetter: makeGetDisplay('display', 'es'),
      renderCell: ({ value }: GridCellParams) => (
        <TooltipTable title={value || ''}>
          <span>{value}</span>
        </TooltipTable>
      ),
      sortable: true,
    },
    {
      field: 'n',
      type: 'string',
      align: 'right',
      headerName: 'Número',
      disableColumnMenu: true,
      flex: 0.4,
      valueFormatter: ({ value }) => `${value}`,
      sortable: true,
    },
    {
      field: 'percentage',
      type: 'string',
      align: 'right',
      headerName: 'Porcentage',
      disableColumnMenu: true,
      flex: 0.4,
      valueFormatter: ({ value }) => `${value}%`,
      sortable: true,
    },
  ];

  return (
    <div className="chart-widget-card-responsive">
      <div className="chart-widget-card-header">
        <div>
          <b>{resultDisplay.es}</b>
        </div>
      </div>
      {chartType === 'bar' &&
        <ResponsiveContainer minWidth={"100%"} height={100 * chartData.length}> 
          <BarChart
            data={chartData}
            layout='vertical'
            margin={{ left: 150, right: 80}}
          >
            <XAxis 
              hide 
              axisLine={false} 
              type="number" 
            />
            <YAxis
              yAxisId={0}
              dataKey="display.display.es"
              type="category"
              tickLine={false}
              tick={YAxisLeftTick}
            />
            <YAxis
              yAxisId={1}
              orientation="right"
              dataKey="percentage"
              type="category"
              axisLine={false}
              tickLine={false}
              tickFormatter={value => `${value}%`}
              mirror={true}
              tick={{
                transform: `translate(${60}, 0)`
              }}
              style={{zIndex: 10}}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <Tooltip
              offset={40}
              separator=": "
              formatter={(v: any, name: any, { payload }: any) => [` ${payload.n} respuestas - ${v}%`]}
            />
            <Bar dataKey='percentage' minPointSize={2} barSize={32} >
              {chartData.map((bar, idx) => {
                return <Cell key={bar.percentage} fill={idx === 0 ? "#30b131" : '#457AA6'} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
       {chartType === 'pie' &&
       <PieChart width={730} height={300}>
          <Pie 
            data={chartData} 
            dataKey='percentage' 
            nameKey="display.display.es" 
            fill="#8884d8"
            label={renderCustomizedLabel}
            labelLine={false}
            outerRadius={'100%'}
          >
           {chartData.map((pie) => {
              return <Cell key={pie.distinctId} fill={getColorByKey(pie.display.display.es)} />;
            })}
          </Pie>
          <Tooltip
              offset={40}
              separator=": "
              formatter={(v: any, name: any, { payload }: any) => [`"${payload.display.display.es}" ${payload.n} respuestas - ${v}%`]}
            />
            <Legend verticalAlign="bottom" height={36} margin={{top: 24}}/>
       </PieChart>
       }
       <div style={{marginTop: '12px'}}>
          <b style={{fontSize: '14px'}}>Base: {KpiCount.report.n} respuestas</b>
        </div>
        {showTable &&
        <div style={{width: '96%', padding: '12px'}}>
          <BaseTable
          rowIdKey="distinctId"
          rows={chartData}
          columns={cols}
          loading={loading}
          onReload={loadReport}
          rowHeight={32}
          style={{minHeight: '200px', maxHeight:'500px'}}
          tableTitle={'Datos'}
          disableSelectionOnClick
          disableDensitySelector
          disableColumnSelector
          disableColumnFilter
          autoPageSize
          hideFooter
          localeText={{
            toolbarFilters: '',
            toolbarExport: '',
            toolbarColumns: 'Columnas',
            noRowsLabel: 'No se encontraron registros.',
            toolbarExportCSV: 'Descargar CSV',
            toolbarExportLabel: 'Exportar',
            MuiTablePagination: {
              labelRowsPerPage: 'Datos por página:',
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
            }
          }}
        />
        </div>
        }
    </div>
  )
}

export {
  KpiCount
}