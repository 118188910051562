import React, { FC } from 'react';
import { FilterOptions } from '../../../store/slices/filters';
import './selected-filter.css';

export interface SelectedFilterProps {
  onClick: () => void;
}

const SelectedFilter: FC<FilterOptions & SelectedFilterProps> = ({
  display,
  field,
  values,
  onClick
}) => (
  <div className="selected-filter" onClick={onClick}>
    <b>{display}</b> es cualquiera de: {values.map(v => v.label).join(', ')}
  </div>
);

export default SelectedFilter;
export {
  SelectedFilter,
};