import { DateRange, Exports, constants, ServerResponse } from '../utils';
import { storage } from '.';

interface genExportDTO {
  studyId: string;
  query: {
    surveyId?: string;
    [key: string]: any;
  };
  dateRange: {
    valueName: string;
    start: string;
    end: string;
  },
  language?: string;
}

async function getExports (studyId?: string): Promise<Exports> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/export`;
  if (studyId) {
    URL += `?conditions=${JSON.stringify({ studyId })}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Exports> = await fetchResponse.json();
  return response.data;
}

async function genExport (studyId: string, surveyId: string, range: DateRange): Promise<Response> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  const fieldExport = process.env.REACT_APP_EXPORT_FIELD ? process.env.REACT_APP_EXPORT_FIELD : 'createdAt';
  let body: genExportDTO = {
    studyId,
    query: {
      'answerObj.status': {
        '$in': ['leave', 'completed']
      }
    },
    dateRange: {
      valueName: fieldExport,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    }
  };
  if (process.env.REACT_APP_EXPORT_USE_DISPLAY === 'true') {
    body.language = "es";
  }
  if (surveyId) {
    body.query.surveyId = surveyId;
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/export`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  return fetchResponse;
}

export {
  getExports,
  genExport,
}