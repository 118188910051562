// Servicios para almacenar y recuperar datos de localstorage

const saveData = (k: string, val: any, serialize: boolean = false): boolean => {
  let valueToSave = val;
  try {
    if (serialize) valueToSave = JSON.stringify(val);
    localStorage.setItem(k, valueToSave);
    return true;
  } catch (err) {
    console.error(`Error al guardar ${k} en localstorage.`, err);
    return false;
  }
}

const getData = (k: string, isSerialized: boolean = false): any => {
  try {
    let val = localStorage.getItem(k);
    if (isSerialized && val) val = JSON.parse(val);
    return val;
  } catch (err) {
    console.error(`Error al guardar ${k} en localstorage.`, err);
    return null;
  }
}

const removeData = (k: string): boolean => {
  try {
    localStorage.removeItem(k);
    return true;
  } catch (err) {
    console.error(`Error al guardar ${k} en localstorage.`, err);
    return false;
  }
}

export {
  getData,
  saveData,
  removeData,
}