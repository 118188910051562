import { FC, useEffect } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { DownloadReport } from './widgets/download-report';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';


const DownloadScreen: FC = () => {
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();
  const studyId = viewConfig?.studyId ? viewConfig.studyId : '';

  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig && viewConfig.reports ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title={viewConfig.viewOptions.pageTitle}
              showFilters={viewConfig.viewOptions.showFilters}
              showRangeInput={viewConfig.viewOptions.showRangeInput}
              showMainFilters={viewConfig.viewOptions.showMainFilters}
            />
            <DownloadReport allReports={viewConfig.reports} studyId={studyId}/>
          </>
        ) : (
          <div>Ocurrió un problema al cargar los reportes</div>
        )}
    </MainLayout>
  );

}

export {
  DownloadScreen,
}