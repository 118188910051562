import { useRef, useState } from 'react'

type CollapsibleProps = {
  collapsible: boolean
  initialHeight?: number
  children: JSX.Element
}

const Collapsible = ({
   collapsible,
   initialHeight = 0,
   children,
 }: CollapsibleProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  if (!collapsible) return children

  return (
    <div
      ref={containerRef}
      className="collapsible-container"
      style={{ height: isOpen ? `${containerRef?.current?.scrollHeight}px` : `${initialHeight}px` }}
    >
      {isOpen ? null : <div className="collapsible-window"/>}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="collapsible-toggle"
        style={{ height: initialHeight }}
      />
      {children}
    </div>
  )
}

export { Collapsible }
