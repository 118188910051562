import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { SimpleViewConfig } from '../../services/views';

// Define a type for the slice state
interface ViewsState {
  available: SimpleViewConfig[];
}

// Define the initial state using that type
const initialState: ViewsState = {
  available: [],
};

export const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailableViews: (state, action: PayloadAction<SimpleViewConfig[]>) => {
      state.available = action.payload;
    },
  },
})

export const { setAvailableViews } = viewsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectViewsState = (state: RootState) => state.views;
export const selectAvailableViews = (state: RootState) => state.views.available;

export default viewsSlice.reducer;