import React, { FC, CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';
import { studies, exports } from '../../../services';
import { DateRangeInput, SelectInput } from '../../../common/components';
import { DateRange, Surveys } from '../../../utils/models';

const wrapperTmpStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 24px',
  backgroundColor: 'white',
  borderRadius:12,
};

type SuccessCallback = () => void;

interface GenExportFormProps {
  onSuccess?: SuccessCallback;
};

const GenExportForm: FC<GenExportFormProps> = ({
  onSuccess,
}) => {
  const studyId = useAppSelector(selectSlice)?.selected?._id;
  const [availableSurveys, setAvailableSurveys] = useState<Surveys>([]);
  const [survey, setSurvey] = useState('');
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange>();

  async function genExport () {
    if (!studyId || !dateRange) return;
    try {
      await exports.genExport(
        studyId,
        survey,
        dateRange,
      );
      onSuccess && onSuccess();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function loadSurveys() {
      if (!studyId) return;
      try {
        setLoading(true);
        const surveys = await studies.getStudySurveys(studyId);
        setSurvey('');
        setAvailableSurveys(surveys);
      } catch (err) {
        console.log('No fue posible cargar las encuestas');
      } finally {
        setLoading(false);
      }
    }
    loadSurveys();
  }, [studyId]);
  
  return (
    <div style={wrapperTmpStyles}>
      <label className="input-label">Cuestionario</label>
      <SelectInput
        noneLabel={loading ? 'Cargando cuestionarios...' : 'Todos los cuestionarios'}
        disabled={loading}
        selectedValue={survey}
        options={availableSurveys}
        onChange={setSurvey}
        style={{
          marginBottom: '16px',
        }}
      />
      <label className="input-label">Periodo</label>
      <DateRangeInput
        onRangeChange={setDateRange}
        immediate
      />
      <button
        className="upload-button"
        onClick={genExport}
        disabled={!dateRange?.start || !dateRange?.end}
      >
        Exportar
      </button>
    </div>
  );
}

export default GenExportForm;
export {
  GenExportForm,
};