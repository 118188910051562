import React, { useEffect, useState } from 'react'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { LocalizedText, SupportedLanguages } from '../../../services/filters'
import { sortBy } from '../../../utils'
import useMultimetricTrend, { MultimetricTrendQuery } from '../../../common/hooks/use-multimetric-trend'
import { ReportConfig } from '../../../services/views/views.models'

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export interface MultimetricTrendTableOptions {
  segmentTitle: string,
  resultToDisplay: 'n' | 'result',
  scaleFormatters: Record<string, ScaleFormatDef[]>,
}

export type MultimetricTrendTableProps = ReportConfig<
  MultimetricTrendQuery,
  MultimetricTrendTableOptions
>

const makeGetDisplay = (field: string, language: SupportedLanguages): GridColDef['valueGetter'] =>
  (params: GridValueGetterParams) => params.row[field]?.[language] ?? '-'

const baseColProps: Partial<GridColDef> = {
  minWidth: 144,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true
}

const makeGetCellClassName = (scaleFormatter: ScaleFormatDef[]) => {
  if (!scaleFormatter) return () => ''
  scaleFormatter.sort(sortBy('max'))
  return (params: GridCellParams) => {
    const scale = scaleFormatter.find(scale => scale.max > (params.value as number))
    if (scale) {
      return scale.className;
    }
    return '';
  }
}

const MultimetricTrendTable = ({
  title = { es: '', default: '' },
  query,
  options: {
    segmentTitle,
    resultToDisplay,
    scaleFormatters,
  },
}: MultimetricTrendTableProps) => {
  const [loadReport, loading, error, multimetricTrendReport] = useMultimetricTrend(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = [
    {
      field: 'display',
      type: 'string',
      headerName: segmentTitle,
      disableColumnMenu: true,
      width: 320,
      valueGetter: makeGetDisplay('display', preferredLang),
      sortable: false
    },
  ]

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && multimetricTrendReport) {
      let extendedCols: GridColDef[] = [...cols]
      const rows = Object.values(multimetricTrendReport.breakdown)
      const periods = rows[0].periods
      
      periods.forEach(p => {
        multimetricTrendReport.varDetails.forEach(v => {
          const prefix = p.timeUnit === 'total' ? 'Global' : `S${p.timeUnit}`
          const colDef: GridColDef = {
            ...baseColProps,
            field: `${p.timeUnit}_${v.distinctId}`,
            headerName: `${prefix} - ${v.display[preferredLang].toLowerCase().includes('nps') ? 'NPS' : 'CSAT'}`,
            cellClassName: makeGetCellClassName(scaleFormatters[v.distinctId])
          }
          extendedCols.push(colDef)
        })
      })

      const newData = rows.map(({ distinctId, display, periods }) => {
        const record: Record<string, any> = {
          distinctId,
          display,
        }
        periods.forEach(p => {
          p.vars.forEach(v => {
            record[`${p.timeUnit}_${v.field}`] = v[resultToDisplay]
          })
        })

        return record
      })

      setTableCols(extendedCols);
      setData(newData);
    }
  }, [loading, error, multimetricTrendReport]);

  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="distinctId"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={48}
        hideFooter
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
        }}
      />
    </div>
  )
}

export {
  MultimetricTrendTable
}