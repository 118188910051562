import { useEffect } from 'react';

interface UseKeypressConfig {
  key: string;
  action: () => any;
}

type UseKeypressHook = (config: UseKeypressConfig) => void;

const useKeypress: UseKeypressHook = ({
  key,
  action
}) => {
  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === key) action()
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);
}

export default useKeypress;