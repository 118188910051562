import React, { FC } from 'react';

const ReloadIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19.998 20">
      <g id="Solid" transform="translate(-24.018 -23.994)">
        <path
          id="Path_1"
          d="M42.982,32.959h0a1.034,1.034,0,0,0-1.034,1.035,7.906,7.906,0,1,1-1.916-5.173H37.12a1.034,1.034,0,1,0,0,2.069h4.827a1.034,1.034,0,0,0,1.034-1.034V25.029a1.034,1.034,0,1,0-2.069,0v1.723A10,10,0,1,0,28.282,42.183,9.925,9.925,0,0,0,34,43.994a10.127,10.127,0,0,0,1.757-.155,10,10,0,0,0,8.263-9.847A1.034,1.034,0,0,0,42.982,32.959Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export {
  ReloadIcon,
}