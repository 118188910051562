import React, { FC } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridLoadIcon,
  GridLoadingOverlay,
  GridColDef,
  GridRowData,
  DataGridProps,
  GridToolbarFilterButton,
  GridSortModel,
  GridFilterModel,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';

const ToolbarIconStyles: React.CSSProperties = {
  padding: 0,
  lineHeight: 1,
  background: 'none',
  color: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'red',
  marginRight: '1em',
};

const BaseTableToolbar = (toolbarProps: any) => {
  // console.log(toolbarProps);
  return (
    <GridToolbarContainer
      style={{ justifyContent: 'space-between' }}
    >
      <b style={{ paddingLeft: '12px' }}>{toolbarProps?.tableTitle}</b>
      <div style={{display: 'flex'}}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <button
          onClick={toolbarProps?.onReload}
          style={ToolbarIconStyles}
        >
          <GridLoadIcon fontSize="small" />
        </button>
        <GridToolbarExport csvOptions={toolbarProps?.csvOptions} />
      </div>
    </GridToolbarContainer>
  );
}

type DataGridExcludedProps = 'sortModel' | 'onSortModelChange' | 'filterModel' | '';

export interface BaseTableProps extends Omit<DataGridProps, DataGridExcludedProps> {
  rows: GridRowData[];
  columns: GridColDef[];
  rowIdKey?: string;
  onReload?: () => void;
  containerHeight?: number;
  initialSortModel?: GridSortModel;
  initialFilterModel?: GridFilterModel;
  disableColumnSelector?: boolean;
  tableTitle?: string;
  downloadFileName?: string;
}

const BaseTable: FC<BaseTableProps> = ({
  rows = [],
  columns = [],
  rowIdKey = '_id',
  onReload,
  containerHeight = 440,
  initialSortModel = [],
  initialFilterModel,
  disableColumnSelector,
  tableTitle = '',
  downloadFileName = 'Datos',
  ...restProps
}) => {
  const [sortModel, setSortModel] = React.useState<GridSortModel>(initialSortModel);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel | undefined>(initialFilterModel);

  return (
    <div style={{ backgroundColor: 'white', borderRadius: 16 }}>
      <DataGrid
        density="compact"
        getRowId={r => r[rowIdKey]}
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        filterModel={filterModel}
        onFilterModelChange={(filterModel: GridFilterModel) => setFilterModel(filterModel)}
        disableSelectionOnClick
        disableDensitySelector
        disableColumnSelector={disableColumnSelector}
        {...restProps}
        localeText={{
          noRowsLabel: 'No se encontraron registros.',
          toolbarExport: '',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          ...restProps?.localeText,
        }}
        components={{
          Toolbar: BaseTableToolbar,
          LoadingOverlay: GridLoadingOverlay,
          ...restProps?.components
        }}
        componentsProps={{
          ...restProps?.componentsProps,
          toolbar: {
            onReload: onReload,
            tableTitle,
            ...restProps?.componentsProps?.toolbar,
            csvOptions: {
              fileName: downloadFileName,
              ...restProps?.componentsProps?.toolbar?.csvOptions,
            },
          }
        }}
      />
    </div>
  );
}

export default BaseTable;
export {
  BaseTable
};
