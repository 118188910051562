import { MetricType } from '../services/analytics/analytics.models';
import { FAILED_TO_FETCH } from './messages';

const FIELD_LABELS: any = {
  reminder: 'Recordatorios',
  invitation: 'Invitaciones',
  leave: 'Parciales',
  completed: 'Completas',
  missing: 'Sin respuesta',
  notMissing: 'Con respuesta',
  result: 'Respuestas',
  difficult: 'Difícil',
  easy: 'Fácil',
  neutral: 'Neutro',
  'Information Exchange': 'Exchange',
  TOP_BOX: 'Top box',
  TOP_2_BOXES: 'Top 2 boxes',
  BOTTOM_BOX: 'Bottom box',
  BOTTOM_2_BOXES: 'Bottom 2 boxes',
}

const _t = (k: string) => FIELD_LABELS[k] || k;

export type NumberSource = string | number | undefined;

const toNumber = (n: NumberSource): string => n === undefined || isNaN(+n) ? '#' : n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const toPercentage = (n: NumberSource, precision: number = 0): string => n === undefined || isNaN(+n) ? '-' : `${(+n).toFixed(precision)}%`;

const toDelta = (n: NumberSource) => !!n ? n > 0 ? `+${n}` : `${n}` : '0';

const roundNumber = (n: NumberSource): number =>
  n === undefined || typeof n === 'string' || isNaN(+n) ? 0 : Math.round(n);

const toFixed = (n: NumberSource, fractionDigits: number = 1): number =>
  n === undefined || isNaN(+n) ? 0 : +(+n).toFixed(fractionDigits);

const getMetricLabel = (k: string, ns: MetricType): string => {
  const defs: any = {
    [MetricType.AVERAGE]: {
      'answer.ai_csat': 'Promedio',
      'answer.ad_csat': 'Promedio',
      'answer.ap_csat': 'Promedio',
      'answer.aa_csat': 'Promedio',
      'answer.ea_csat': 'Promedio',
      'answer.ee_csat': 'Promedio',
      'answer.em_csat': 'Promedio',
      'answer.el_csat': 'Promedio',
      'answer.ek_csat': 'Promedio',
    },
    [MetricType.TOP_BOX]: {
      'answer.ai_csat': 'Satisfacción',
      'answer.ad_csat': 'Satisfacción',
      'answer.ap_csat': 'Satisfacción',
      'answer.aa_csat': 'Satisfacción',
      'answer.ea_csat': 'Satisfacción',
      'answer.ee_csat': 'Satisfacción',
      'answer.em_csat': 'Satisfacción',
      'answer.el_csat': 'Satisfacción',
      'answer.ek_csat': 'Satisfacción',
    },
    [MetricType.TOP_2_BOXES]: {
      'answer.ai_csat': 'Satisfacción',
      'answer.ad_csat': 'Satisfacción',
      'answer.ap_csat': 'Satisfacción',
      'answer.aa_csat': 'Satisfacción',
      'answer.ea_csat': 'Satisfacción',
      'answer.ee_csat': 'Satisfacción',
      'answer.em_csat': 'Satisfacción',
      'answer.el_csat': 'Satisfacción',
      'answer.ek_csat': 'Satisfacción',
    },
    [MetricType.BOTTOM_BOX]: {
      'answer.ai_csat': 'Insatisfacción',
      'answer.ad_csat': 'Insatisfacción',
      'answer.ap_csat': 'Insatisfacción',
      'answer.aa_csat': 'Insatisfacción',
      'answer.ea_csat': 'Insatisfacción',
      'answer.ee_csat': 'Insatisfacción',
      'answer.em_csat': 'Insatisfacción',
      'answer.el_csat': 'Insatisfacción',
      'answer.ek_csat': 'Insatisfacción',
    },
    [MetricType.BOTTOM_2_BOXES]: {
      'answer.ai_csat': 'Insatisfacción',
      'answer.ea_csat': 'Insatisfacción',
      'answer.ee_csat': 'Insatisfacción',
      'answer.em_csat': 'Insatisfacción',
      'answer.el_csat': 'Insatisfacción',
      'answer.ek_csat': 'Insatisfacción',
    },
  };
  
  return defs?.[ns]?.[k] || '-';
}

const translateError = (err: unknown) => {
  const DEFAULT_MESSAGE = 'Ocurrió un error.';
  if (err instanceof Error) {
    switch (err.message) {
      case 'Failed to fetch':
        return FAILED_TO_FETCH;
      //break;
      default:
        return err.message;
      //break;
    }
  }
  if (typeof err === 'string') {
    return err;
  }
  return DEFAULT_MESSAGE;
}

export {
  translateError,
  getMetricLabel,
  toPercentage,
  toNumber,
  roundNumber,
  toFixed,
  toDelta,
  _t,
}