import { CSSProperties, useEffect, useState } from "react";
import { GridLoadIcon, GridSaveAltIcon } from "@mui/x-data-grid";
import { ReportConfig } from "../../../services/views/views.models";
import { DateRange } from "../../../utils";
import { DateRangeInput, SelectInput } from "../../../common/components";
import { reports } from "../../../services";
import { MultimetricTrendReportQuery } from "../../../services/reports";

const wrapperTmpStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  padding: "32px 24px",
  backgroundColor: "white",
  borderRadius: 12,
  margin: "0 auto",
  width: "400px",
};

interface DownloadReportProps {
  allReports: ReportConfig[];
  studyId: string;
}

const DownloadReport = ({ allReports, studyId }: DownloadReportProps) => {
  const [availableReports, setAvailableReports] = useState<any>([]);
  const [nameReport, setNameReport] = useState<any>("");
  const [report, setReport] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [analyticsResult, setAnalyticsResult] = useState<BlobPart | undefined>(
    undefined
  );
  const [analyticsError, setAnalyticsError] = useState<any>();

  useEffect(() => {
    setAvailableReports(allReports);
  }, [allReports]);

  function saveFileAs(blob: BlobPart, suggestedName: string) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${suggestedName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    return;
  }

  async function downloadFile() {
    if (analyticsResult && dateRange) {
      const type = nameReport.includes("NPS") ? "NPS" : "CSAT";
      saveFileAs(
        analyticsResult,
        `${type}_Estación_${dateRange.start?.format(
          "DD-MM-YY"
        )}_${dateRange.end?.format("DD-MM-YY")}.csv`
      );
    }
  }

  async function genReport() {
    if (!dateRange || !report) return;
    try {
      if (dateRange?.start && dateRange?.end) {
        setLoading(true);
        const query: MultimetricTrendReportQuery = {
          studyId,
          dateRange: {
            field: report.query.dateRangeField,
            start: dateRange.start.startOf("day").toISOString(),
            end: dateRange.end.endOf("day").toISOString(),
          },
          breakDownField: report.query.breakDownField,
          timeUnit: report.query.timeUnit,
          variables: report.query.variables,
        };
        const reportResponse = await reports.getDownloadReport(query);
        console.log(reportResponse);
        if (reportResponse.message)
          setAnalyticsError(reportResponse.message.es);
        else {
          setAnalyticsResult(reportResponse);
          setAnalyticsError(undefined);
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function onChange(value: string) {
    setNameReport(value);
    setAnalyticsResult(undefined);
    setAnalyticsError(undefined);
    const result = allReports.find((report: any) => report.name === value);
    if (result) setReport(result);
    else setReport(undefined);
  }

  function onChangeDate(range: DateRange) {
    setDateRange(range);
    setAnalyticsResult(undefined);
    setAnalyticsError(undefined);
  }

  return (
    <div style={wrapperTmpStyles}>
      <label className="input-label">Reportes</label>
      <SelectInput
        noneLabel={loading ? "Cargando reportes..." : "Selecciona un reporte"}
        disabled={loading}
        selectedValue={nameReport}
        options={availableReports}
        onChange={(value: string) => onChange(value)}
        style={{
          marginBottom: "16px",
        }}
        multiple={false}
      />
      <label className="input-label">Periodo</label>
      <DateRangeInput
        onRangeChange={(range: DateRange) => onChangeDate(range)}
        immediate
      />
      <button
        style={{
          background:
            dateRange && report ? process.env.REACT_APP_PRIMARY_COLOR : "gray",
          color: "white",
          cursor: dateRange && report ? "pointer" : "auto",
          padding: "8px",
          minWidth: "260px",
          marginTop: "18px",
        }}
        onClick={genReport}
        disabled={!dateRange?.start || !dateRange?.end || loading}
      >
        {loading ? "Cargando..." : "Generar"}
      </button>
      {analyticsResult && (
        <button
          style={{
            background: analyticsResult
              ? process.env.REACT_APP_PRIMARY_COLOR
              : "gray",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: analyticsResult ? "pointer" : "auto",
            minWidth: "260px",
            marginTop: "18px",
          }}
          onClick={() => downloadFile()}
          disabled={loading}
        >
          {loading ? "Cargando..." : nameReport + " "}
          {loading ? (
            <GridLoadIcon style={{ margin: "8px" }} />
          ) : (
            <GridSaveAltIcon style={{ margin: "8px" }} />
          )}
        </button>
      )}
      {analyticsError && (
        <p style={{ color: "red", margin: "12px" }}>{analyticsError}</p>
      )}
    </div>
  );
};

export { DownloadReport };
