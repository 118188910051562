import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { FilterOptions } from '../../store/slices/filters';
import { RemoteFilter } from './filters.models';
import { ViewConfig } from '../views';

export interface AvailableFiltersOptions {
  studyId?: string;
  viewName: string;
}

export function parseFilters (filters: RemoteFilter[]): FilterOptions[] {
  return filters.map(f => ({
    display: f.name,
    field: `${f.prefix}`,
    values: f.choices.map(c => ({
      label: c.display.es, // TODO: Use app language
      value: c.value,
    }))
  }))
}

// Consulta de filtros
async function getAvailableFilters ({
  studyId,
  viewName = 'comments', // TODO: Remove default
}: AvailableFiltersOptions): Promise<FilterOptions[]> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  
  let URL = `${constants.API_URL}/view-config?conditions=${JSON.stringify({ studyId, viewName })}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<ViewConfig[]> = await fetchResponse.json();
  const viewConfig = response.data.shift();
  let availableFilters: FilterOptions[] = [];
  if (viewConfig) {
    availableFilters =
      parseFilters(viewConfig.mainFiltersObj).concat(parseFilters(viewConfig.filtersObj));
  }
  return availableFilters;
}

export {
  getAvailableFilters,
}