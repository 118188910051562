import React, { FC, useEffect, useState } from 'react';
import useComments, { UseCommentsConfig } from '../../../common/hooks/use-comments';
import { Comment, CommentProps } from '../../../common/components';
import { ReportConfig } from '../../../services/views';
import { useAppSelector } from '../../../store/hooks';
import { getAutocompleteSuggestionsByFilterDisplay } from '../../../store/slices/filters';

export type CommentExplorerProps = ReportConfig<
  UseCommentsConfig,
  {
    tagName?: string;
    suggestionsFilterName?: string,
    editable?: boolean;
    fields: CommentProps['fields'];
  }
>

const CommentExplorer: FC<CommentExplorerProps> = ({
  query,
  options
}) => {
  const pageSize = 25
  const [page, setPage] = useState(0);
  let execQuery = {
    ...query,
    limit: pageSize,
    skip: pageSize * page
  };
  const [loadComments, loading, error, comments, dateRange, filters] = useComments(execQuery);
  const editable = options?.editable !== undefined ? options.editable : false;
  const tagName = options?.tagName !== undefined ? options.tagName : '';
  const suggestionsFilterName = options?.suggestionsFilterName || '';
  const [commentsList, setCommentsList] = useState<Array<any>>([])
  const mainElement = document.querySelector('main')
  const suggestions =
    useAppSelector(
      state => getAutocompleteSuggestionsByFilterDisplay(state, suggestionsFilterName)
    )

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  useEffect(() => {
    setCommentsList([])
    setPage(0)
  }, [dateRange]);

  useEffect(() => {
    setCommentsList([])
    setPage(0)
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      if (mainElement){
        const {scrollTop, clientHeight, scrollHeight} = mainElement;
        if(scrollHeight >= 500 && scrollTop + clientHeight >= scrollHeight){
          setPage(page + 1);
        }
      }
    }

    mainElement?.addEventListener('scroll', handleScroll);
    return ()  => {
      mainElement?.removeEventListener('scroll', handleScroll);
    }
  }, [mainElement, page]);

  useEffect(() => {
    if (Array.isArray(comments)) {
      setCommentsList([...commentsList, ...comments])
    }
  }, [comments]);

  return (
    <div className="comment-explorer">
      <div className="comment-list">
        {commentsList?.map((comment:any) => (
            <Comment
              key={comment._id}
              data={comment}
              fields={options.fields}
              editable={editable}
              suggestions={suggestions}
              tagName={tagName}
            />
          ))
        }
        {loading && <p>Cargando datos</p>} 
        </div>
    </div>
  );
}

export {
  CommentExplorer,
}