import { constants } from '../utils';
import { storage } from '.';

export enum DownloadType {
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT',
  DISCARDED = 'DISCARDED',
  VALID = 'VALID',
  ORIGINAL = 'ORIGINAL'
}

const downloadUrls: Record<DownloadType, (id: string) => string> = {
  [DownloadType.EXPORT]: (id) => `${constants.API_URL}/export/${id}/download`,
  [DownloadType.IMPORT]: (id) => `${constants.API_URL}/file/${id}/download`,
  [DownloadType.DISCARDED]: (id) => `${constants.API_URL}/import/${id}/discarded`,
  [DownloadType.VALID]: (id) => `${constants.API_URL}/import/${id}/valid`,
  [DownloadType.ORIGINAL]: (id) => `${constants.API_URL}/import/${id}/original`,
}

async function downloadCSV (downloadType: DownloadType, id: string): Promise<BlobPart> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = downloadUrls[downloadType](id);
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: BlobPart = await fetchResponse.blob();
  return response;
}

export {
  downloadCSV,
}