import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components'
import { ImportsTable } from './widgets';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';

interface ImportsScreenProps {
};

const ImportsScreen: FC<ImportsScreenProps> = () => {
  const history = useHistory();
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();

  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);

  function goToNewImport () {
    return history.push('/new-import')
  }

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title={viewConfig.viewOptions.pageTitle}
              actionLabel="Nuevo envío"
              action={goToNewImport}
              showFilters={false}
              showRangeInput
              showMainFilters={viewConfig.viewOptions.showMainFilters}
            />
            <ImportsTable />
          </>
        ) : (
          <div>Ocurrió un problema al cargar los envíos.</div>
        )
      }
    </MainLayout>
  );
}

export default ImportsScreen;
export {
    ImportsScreen,
};