import React, { FC } from 'react';
import { Navbar, NavbarProps } from '../components';
import SimpleNav from '../components/navbar-simple';
interface CenteredLayoutProps {
  title?: string;
  navbarProps?: NavbarProps;
  useSimpleNav?: boolean;
};

const CenteredLayout: FC<CenteredLayoutProps> = ({
  title,
  navbarProps,
  useSimpleNav = false,
  children
}) => {
  return (
    <div className="screen">
      {useSimpleNav
        ? <SimpleNav />
        : <Navbar {...navbarProps} />
      }
      <main className="main" style={{ paddingTop: useSimpleNav ? 80 : 0 }}>
        <div className="container centered">
          {title && (
            <div className="centered-page-header">
              <h1>{title}</h1>
            </div>
          )}
          {children}
        </div>
      </main>
    </div>
  );
}

export default CenteredLayout;
export {
  CenteredLayout
};