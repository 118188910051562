import React, { FC } from 'react';

interface AuthLayoutProps {
  title: string;
};

const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  children
}) => {
  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <img src={process.env.REACT_APP_URL_BRAND_LOGO} alt="" className="auth-brand-image" />
        <h1 className="auth-heading">{title}</h1>
        { children }
      </div>
      <div className="auth-promo">
        <img
          className="auth-promo-image"
          alt=""
          src={process.env.REACT_APP_URL_BRAND_BG}
        />
      </div>
    </div>
  );
}

export default AuthLayout;
export {
  AuthLayout
};