import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { selectDateRange, selectFilters } from '../../store/slices/filters';
import { reports } from '../../services';
import { NpsBreakdownReport, NpsBreakdownReportQuery } from '../../services/reports';
import { messages } from '../../utils';
import { Filter } from "../../services/analytics";

export type NpsBreakdownConfig =
  Omit<NpsBreakdownReportQuery, 'studyId' | 'filters' | 'dateRange'> & {
    dateRangeField: string,
    baseFilters?: Filter[],
  }

type UseNpsBreakdownHook = (config: NpsBreakdownConfig) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  NpsBreakdownReport | undefined
];

const useNpsBreakdown: UseNpsBreakdownHook = ({
  dateRangeField,
  breakDownField,
  npsField,
  baseFilters = []
}) => {
  const studies = useAppSelector(selectSlice);
  const dateRange = useAppSelector(selectDateRange);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<NpsBreakdownReport | undefined>(undefined);
  const hasBaseFilters = baseFilters.length;

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        if (!dateRange.start || !dateRange.end) {
          return setAnalyticsError(new Error('No hay periodo de tiempo seleccionado'));
        }
        const query: NpsBreakdownReportQuery = {
          studyId: studies.selected._id,
          dateRange: {
            field: dateRangeField,
            start: dateRange.start.startOf('day').toISOString(),
            end: dateRange.end.endOf('day').toISOString(),
          },
          npsField,
          breakDownField,
        }
        if (hasBaseFilters) {
          query.filters = baseFilters
        }
        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters]
          } else {
            query.filters = standarizedFilters
          }
        }
        let analyticsResponse = await reports.getNpsBreakdownReport(query);
        if (analyticsResponse.length < 1) throw new Error(messages.NO_DATA_FOR_QUERY);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
      breakDownField,
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ]

}

export default useNpsBreakdown;
