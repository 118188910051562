import React, { useState } from 'react'
import { NpsBreakdownConfig } from '../../../common/hooks/use-nps-breakdown'
import { LocalizedText } from '../../../services/filters'
import { ReportConfig } from '../../../services/views'
import { NpsBreakdownReportTable } from './nps-breakdown.table'
import useKeypress from '../../../common/hooks/use-keypress'

export type ColFormat = 'percentage' | 'number';

export type NpsBreakdownDrilldownTableOptions = {
  varsTitle: LocalizedText,
  collapsible: boolean,
  colsToShow: Array<{
    field: string,
    hide: boolean,
    display: LocalizedText,
    format: ColFormat,
  }>
}

export type NpsBreakdownDrilldownTableProps = ReportConfig<
  NpsBreakdownConfig,
  NpsBreakdownDrilldownTableOptions
>

const DRILLDOWN_ENABLED = [
  'enrichedRecord.arrivalStationManager',
  'enrichedRecord.arrivalStation',
]

const NpsBreakdownDrilldownTable = (props: NpsBreakdownDrilldownTableProps) => {
  const { query, options, type } = props
  const isDrilldownEnabled = DRILLDOWN_ENABLED.includes(query.breakDownField)
  const [selectedRow, setSelectedRow] = useState<Record<string, any>>({})
  useKeypress({
    key: 'Escape',
    action: () => setSelectedRow({}),
  })

  return (
    <>
      <div className={selectedRow?.id ? 'comment-backdrop active' : 'comment-backdrop'}>
        <div className="comment-backdrop-close" onClick={() => setSelectedRow({})}>Cerrar</div>
        {selectedRow?.id && (
          <div className="widget-grid">
            <NpsBreakdownReportTable
              type={type}
              query={{
                ...query,
                breakDownField: 'answerObj.data.P2_submotive',
                baseFilters: query.baseFilters?.length 
                  ? [...query.baseFilters, { field: query.breakDownField, values: Array.isArray(selectedRow.id) ? selectedRow.id : [selectedRow.id] }] 
                  : [{ field: query.breakDownField, values: Array.isArray(selectedRow.id) ? selectedRow.id : [selectedRow.id] }]
              }}
              options={{ ...options, varsTitle: { default: 'Comment reason', es: 'Submotivos' } }}
              title={{ default: `NPS by baggage comment reasons - ${selectedRow?.display?.default}`, es: `NPS por submotivos de equipaje - ${selectedRow?.display?.es}` }}
            />
          </div>
        )}
      </div>
      <NpsBreakdownReportTable
        {...props}
        onRowClick={
          isDrilldownEnabled ? ({ id, row }) => setSelectedRow({ id: row._id, ...row }) : undefined
        }
      />
    </>
  )
}

export {
  NpsBreakdownDrilldownTable,
}
