import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';
import { imports } from '../../../services'

const FileUploadForm: FC = () => {

  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState <FileList>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const studies = useAppSelector(selectSlice);

  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let file = files?.[0];
    if (!files || !file) {
      setSelectedFile(undefined)
      setIsFilePicked(false)
      return
    };

    const acceptedMimeTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]
    
    if (file.name.endsWith('.csv') || acceptedMimeTypes.includes(file.type)) {
      setSelectedFile(files)
      setIsFilePicked(true)
    } else {
      setSelectedFile(undefined)
      setIsFilePicked(false)
      e.target.value = '';
      alert(`Por favor selecciona un archivo CSV. Archivo seleccionado: ${file.name} (${file.type})`);
    }
  }

	const handleSubmission = async () => {
    if (selectedFile && studies.selected) {
      setLoading(true);
      try {
        await imports.importByFile(studies.selected._id, studies.selected.importDatamapId, selectedFile)
        history.push('/imports');
      } catch (error) {
        console.error('Error:', error);
        alert('Ocurrió un problema al importar el archivo.');
      } finally {
        setLoading(false);
      }
    }
  };
  /*const _renderInfo = () =>{
    return (
      <div>
        <p>Filename: {selectedFile?.[0].name}</p>
        <p>Filetype: {selectedFile?.[0].type}</p>
        <p>Size in bytes: {selectedFile?.[0].size}</p>
        <p>
          lastModifiedDate:{' '}
          {selectedFile?.[0].lastModified.toLocaleString()}
        </p>
      </div>
    )
  }*/

  const message = isFilePicked ? `Se cargarán y procesarán los registros del archivo ${selectedFile?.[0].name}` : `Por favor selecciona un archivo CSV con contactos para el estudio ${studies.selected?.name}.`;

  return (
    <div>
      <div className="file-input">
        <input type="file" name="file" onChange={changeHandler} style={{ marginBottom: 30, width: '100%' }}/>
        {/* {isFilePicked 
          ? _renderInfo()
          : <p>Detalles del archivo</p>
        } */}
        <div className="input-guide">
          {message}
        </div>
      </div>
      <input
        disabled={!isFilePicked || loading}
        type="submit"
        value={loading ? `Cargando archivo...` : `Cargar archivo`}
        className="upload-button"
        onClick={handleSubmission}
      />
    </div>
  );
}

export default FileUploadForm;
export {
  FileUploadForm,
}