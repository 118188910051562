import React, { FC, CSSProperties } from "react";
import { MetricType } from "../../services/analytics";
import { BarChartIcon, LineChartIcon } from "../icons";
import './global-selector.css';

interface CardHeaderProps {
  title: string;
  support: string;
  meta: string;
}

const cardStyle: CSSProperties = {
  backgroundColor: 'white',
  borderRadius: 12,
  padding: 16,
  marginBottom: 16,
  minHeight: 325,
  display: 'flex',
  flexDirection: 'column',
}

const CardHeader: FC<CardHeaderProps> = ({
  title,
  support,
  meta,
}) => (
  <div>
    <p style={{ fontSize: '1.25em', lineHeight: 1.25 }}>{title}</p>
    <p style={{ fontSize: '0.75em', lineHeight: 1.25 }}>{support || ''}</p>
    <p style={{ fontSize: '10px', lineHeight: 1.25 }}>{meta}</p>
  </div>
);

export enum ChartType {
  BAR = 'bar',
  TREND = 'trend',
};

interface Option<Type> {
  label: string;
  value: Type;
}

interface CardControlProps<Type> {
  selectedValue: Type;
  onChange: (value: Type) => void;
  disabled?: boolean;
}

const metricOptions: Option<MetricType>[] = [
  {
    label: 'Top',
    value: MetricType.TOP_BOX
  },
  {
    label: 'Top 2',
    value: MetricType.TOP_2_BOXES
  },
  {
    label: 'Bottom',
    value: MetricType.BOTTOM_BOX
  },
  {
    label: 'Bottom 2',
    value: MetricType.BOTTOM_2_BOXES
  },
]

const MetricCardControl: FC<CardControlProps<MetricType>> = ({
  selectedValue,
  onChange,
  disabled,
}) => (
  <div className="global-selector">
    <div className="global-selector-label">Métrica</div>
    <div className="global-selector-options">
      {metricOptions.map(({ label, value }) => {
        let classes = value === selectedValue ? 'global-selector-item active' : 'global-selector-item';
        if (disabled) {
          classes += ' disabled'
        }
        return (
          <div className={classes} key={value} onClick={() => !disabled && onChange(value)}>
            <div className="global-selector-item-dot"></div>
            {`${label}`}
          </div>
        );
      })}
    </div>
  </div>
);

const chartOptions: Option<ChartType>[] = [
  {
    label: 'Barra',
    value: ChartType.BAR,
  },
  {
    label: 'Tendencia',
    value: ChartType.TREND,
  },
]

const ChartCardControlStyle: CSSProperties = {
  display: 'grid',
  columnGap: '8px',
  gridTemplateColumns: '1fr 1fr',
};

const IconButtonStyle: CSSProperties = {
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // stroke: '#000000',
  // fill: '#000000',
  color: '#000000',
  backgroundColor: '#DFE2E3',
}

const IconButtonActiveStyle: CSSProperties = {
  // stroke: '#FFFFFF',
  // fill: '#FFFFFF',
  color: '#FFFFFF',
  backgroundColor: '#FC4C02',
}

const ChartCardControl: FC<CardControlProps<ChartType>> = ({
  selectedValue,
  onChange,
}) => (
  <div style={ChartCardControlStyle}>
    {chartOptions.map(({ label, value }) => {
      const extraStyles = value === selectedValue ? IconButtonActiveStyle : {};
      return (
        <div key={value} onClick={() => onChange(value)} style={{ ...IconButtonStyle, ...extraStyles }}>
          {value === ChartType.BAR && <BarChartIcon />}
          {value === ChartType.TREND && <LineChartIcon />}
        </div>
      );
    })}
  </div>
);



interface CardProps {
  aditionalStyles?: CSSProperties;
}

const Card: FC<CardProps> = ({
  aditionalStyles,
  children
}) => (
  <div style={{ ...cardStyle, ...aditionalStyles }}>
    {children}
  </div>
);



export default Card;
export {
  Card,
  MetricCardControl,
  ChartCardControl,
  CardHeader,
};