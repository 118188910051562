import React, { FC } from 'react';
import { Study } from '../../../utils';
import { useAppSelector } from '../../../store/hooks';
import { selectAvailableViews } from '../../../store/slices/views';

interface StudyCardProps extends Study {
  onClick?: any;
}

const StudiesCard: FC<StudyCardProps> = ({
  _id,
  name,
  onClick,
}) => {
  const availableViews = useAppSelector(selectAvailableViews)
  const navOptions = availableViews.filter(view => view.viewOptions.showOnNavbar && _id === view.studyId)

  return (
    <div className="study-card">
      <div>{name}</div>
      <ul className="study-card-menu">
        {navOptions.map(sc => (
          <li
            key={`${_id}${sc.viewName}`}
            onClick={() => onClick && onClick(_id, sc.viewName)}
            className="study-card-link"
          >
              {sc.viewOptions.pageTitle}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default StudiesCard;