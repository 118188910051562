import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Study } from '../../utils/models';

// Define a type for the slice state
interface StudiesState {
  available: Study[];
  selected?: Study;
}

// Define the initial state using that type
const initialState: StudiesState = {
  available: [],
};

export const studiesSlice = createSlice({
  name: 'studies',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailable: (state, action: PayloadAction<Study[]>) => {
      state.available = action.payload;
    },
    setStudy: (state, action: PayloadAction<string>) => {
      const nextStudy = state.available.find(st => st._id === action.payload);
      if (nextStudy) {
        state.selected = nextStudy;
      }
    },
  },
})

export const { setAvailable, setStudy } = studiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSlice = (state: RootState) => state.studies;
export const selectStudies = (state: RootState) => state.studies.available;

export default studiesSlice.reducer;