import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CenteredLayout } from '../../common/layouts';
import GenExportForm from './widgets/gen-export.form';

interface NewExportScreenProps {
};

const NewExportScreen: FC<NewExportScreenProps> = (
  
) => {
  const history = useHistory();
  
  return (
    <CenteredLayout
      title="Exportar respuestas"
      navbarProps={{ showControls: true, showMenu: true }}
    >
      <GenExportForm
        onSuccess={() => history.push('/exports')}
      />
    </CenteredLayout>
  );
}

export default NewExportScreen;
export {
  NewExportScreen,
};