import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { selectDateRange, selectFilters } from '../../store/slices/filters';
import { reports } from '../../services';
import { KpiTrendReport, KpiTrendReportQuery } from '../../services/reports';
import { KpiTrendConfig } from '../../screens/analytics/widgets/kpi-trend.w';
import { messages } from '../../utils';

type UseKpiTrendHook = (config: KpiTrendConfig['query']) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  KpiTrendReport | undefined
];

const useKpiTrend: UseKpiTrendHook = ({
  dateRangeField,
  field,
  calc,
  timeUnit,
  resultId,
  baseFilters = [],
}) => {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<KpiTrendReport | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const hasBaseFilters = baseFilters.length

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        if (!dateRange.start || !dateRange.end) {
          return setAnalyticsError(new Error('No hay periodo de tiempo seleccionado'));
        }
        const query: KpiTrendReportQuery = {
          studyId: studies.selected._id,
          dateRange: {
            field: dateRangeField,
            start: dateRange.start.startOf('day').toISOString(),
            end: dateRange.end.endOf('day').toISOString(),
          },
          field,
          calc,
          timeUnit
        }
        if (resultId) query.resultId = resultId
        if (hasBaseFilters) {
          query.filters = baseFilters
        }
        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters]
          } else {
            query.filters = standarizedFilters
          }
        }
        let analyticsResponse = await reports.getKpiTrendReport(query);
        if (analyticsResponse?.periods?.length < 1) throw new Error(messages.NO_DATA_FOR_QUERY);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
      timeUnit
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ]

}

export default useKpiTrend;