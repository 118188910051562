import React from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectFilters, selectFiltersSlice, selectMainFilters, setFilters } from '../../../store/slices/filters';

export const MainFilters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFiltersSlice);
  const mainFilters = useAppSelector(selectMainFilters);
  const getSelectedValues = (fieldName: string) => filters.selected.find(sf => sf.field === fieldName)?.values;

  function onFilterChange (fieldDisplay: string, fieldName: string, values: any[]) {
    dispatch(setFilters({
      display: fieldDisplay,
      field: fieldName,
      values,
    }));
  }

  return (
    <div className="main-filters-bar">
      { mainFilters.map(filter => (
        <MultiSelect
          key={filter.field}
          options={filter.values || []}
          value={getSelectedValues(filter.field) || []}
          onChange={(values: Option[]) => onFilterChange(filter.display, filter.field, values)}
          labelledBy="Filtro"
          valueRenderer={
            (selected, allOptions) =>
              selected.length > 0 ?
                `${filter.display}: ${selected.length} seleccionados` :
                filter.display
          }
          disableSearch={filter.values.length < 6}
          overrideStrings={{
            allItemsAreSelected: 'Todos seleccionados',
            clearSearch: 'Limpiar',
            noOptions: 'No hay opciones',
            search: `Buscar ${filter.display}`,
            selectAll: 'Seleccionar todo',
            selectAllFiltered: 'Seleccionar elementos filtrados',
            selectSomeItems: `Seleccionar ${filter.display}`,
          }}
        />
      )) }
    </div>
  )
}