import React, { FC, useState, } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import './date-range-input.css';
import { MomentRange } from '../../utils';
import { ReloadIcon } from '../icons/reload';

type MomentDate = Moment | null;

interface DateChangeShape {
  startDate: MomentDate;
  endDate: MomentDate;
}

interface DateRangeInputProps {
  initialValue?: MomentRange;
  onRangeChange: any;
  immediate?: boolean;
  disabled?: boolean;
  showRangeInputCompare?: boolean;
  compareInitialValue?: MomentRange;
  onCompareRangeChange?: any;
}

const DateRangeInput: FC<DateRangeInputProps> = ({
  initialValue,
  compareInitialValue,
  onRangeChange,
  onCompareRangeChange = () => {},
  immediate,
  disabled,
  showRangeInputCompare,
}) => {
  const [startDate, setStartDate] = useState<MomentDate>(initialValue?.start || null);
  const [endDate, setEndDate] = useState<MomentDate>(initialValue?.end || null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  
  const [compareStartDate, setCompareStartDate] = useState<MomentDate>(compareInitialValue?.start || null);
  const [compareEndDate, setCompareEndDate] = useState<MomentDate>(compareInitialValue?.end || null);
  const [compareFocusedInput, setCompareFocusedInput] = useState<FocusedInputShape | null>(null);

  function isDayBlocked (day: Moment) {
    const today = moment().startOf('day');
    const diff = today.diff(day.startOf('day'));
    if (diff >= 0) {
      return false;
    }
    return true;
  }

  function onDatesChange (change: DateChangeShape) {
    const { startDate: start, endDate: end } = change;
    setStartDate(moment(start?.startOf('day')));
    setEndDate(end);
    console.log(startDate, endDate);
    if (immediate && start && end) {
      onRangeChange({ start, end });
    }
  }
  
  function onCompareChange (change: DateChangeShape) {
    const { startDate: start, endDate: end } = change;
    setCompareStartDate(start);
    setCompareEndDate(end);
    if (immediate && start && end) {
      onCompareRangeChange({ start, end });
    }
  }

  function reloadDates () {
    onRangeChange({ start: startDate, end: endDate })
    onCompareRangeChange({ start: compareStartDate, end: compareEndDate })
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DateRangePicker
          startDateId="start"
          endDateId="end"
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          startDatePlaceholderText="Inicio"
          endDatePlaceholderText="Fin"
          firstDayOfWeek={1}
          displayFormat="DD/MM/YYYY"
          isDayBlocked={isDayBlocked}
          minimumNights={0}
          isOutsideRange={() => ( false )}
          initialVisibleMonth={null}
          noBorder
          disabled={disabled}
          small
        />
        {!immediate && (
          <button
            className="reload-button"
            disabled={disabled || !startDate || !endDate}
            onClick={reloadDates}
          >
            <ReloadIcon />
          </button>
        )}
      </div>
      {showRangeInputCompare && (
        <div style={{ fontSize: '12px', position: 'relative', lineHeight: '40px', paddingTop: 4 }}>
          <span style={{ position: 'absolute', left: 0, transform: 'translateX(-100%)', paddingRight: 16 }}>
            Comparado con:
          </span>
          <DateRangePicker
            startDateId="start"
            endDateId="end"
            focusedInput={compareFocusedInput}
            onFocusChange={setCompareFocusedInput}
            startDate={compareStartDate}
            endDate={compareEndDate}
            onDatesChange={onCompareChange}
            startDatePlaceholderText="Inicio"
            endDatePlaceholderText="Fin"
            firstDayOfWeek={1}
            displayFormat="DD/MM/YYYY"
            isDayBlocked={isDayBlocked}
            minimumNights={0}
            isOutsideRange={() => ( false )}
            initialVisibleMonth={null}
            noBorder
            disabled={disabled}
            small
        />
        </div>
      )}
    </div>
  );
}

export default DateRangeInput;
export {
  DateRangeInput,
}