import React, { FC } from 'react';
import { IntervalType } from '../../services/analytics';

interface Option<Type> {
  label: string;
  value: Type;
}

const intervalOptions: Option<IntervalType>[] = [
  {
    label: 'Día',
    value: IntervalType.DAY,
  },
  {
    label: 'Semana',
    value: IntervalType.WEEKLY,
  },
  {
    label: 'Mes',
    value: IntervalType.MONTHLY,
  },
];

interface IntervalSelectorProps<Type> {
  selectedValue: Type | undefined;
  onChange: (newValue: Type) => void;
  disabled?: boolean;
}

const IntervalSelector: FC<IntervalSelectorProps<IntervalType>> = ({
  selectedValue,
  onChange,
  disabled,
}) => {

  function callOnChange (e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const newValue = e.target.value as IntervalType;
    onChange && onChange(newValue);
  }

  return (
    <select
      className="w-select"
      value={selectedValue}
      onChange={callOnChange}
      style={{
        width: '200px',
        borderRadius: '12px',
        backgroundPositionX: '90%',
        fontSize: '0.9em',
        border: '1px solid #dfe2e3'
      }}
      disabled={disabled}
    >
      <option value="" disabled>Intervalo</option>
      {intervalOptions.map(({ label, value }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  );

}

export default IntervalSelector;
export { IntervalSelector };