import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TextInput } from '../../../common/components';

type RecoverPassFormProps = {

};

const RecoverPassForm: FC<RecoverPassFormProps> = (
  
) => {
  return (
    <form style={{ width: '100%' }}>
      <p className="auth-guide">
        Ingresa tu correo electrónico registrado en CX Mate y 
        te enviaremos las instrucciones para crear una nueva contraseña.
      </p>
      <TextInput label="Email" fieldName="email" />
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link">
          Regresar a inicio de sesión
        </Link>
      </div>
      <input type="submit" value="Restablecer contraseña" className="form-submit-button w-button" />
    </form>
  );
}

export default RecoverPassForm;
export {
  RecoverPassForm,
};