import React, { FC, CSSProperties } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth } from '../../services';

const SimpleNavStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 16,
  backgroundColor: '#fff',
  width: '100%',
  position: 'fixed',
  top: 0,
}

interface SimpleNavProps {}

const SimpleNav: FC<SimpleNavProps> = () => {

  const history = useHistory();

  function logout () {
    auth.logout();
    history.replace('/auth/login');
  }

  return (
    <nav style={SimpleNavStyle}>
      <Link to="/start" style={{ alignSelf: 'center' }}>
        <img src={process.env.REACT_APP_URL_BRAND_LOGO} loading="lazy" alt="" className="navbar-brand" />
      </Link>
      <a className="navbar-link" style={{ cursor: 'pointer' }} onClick={logout}>
        Cerrar sesión
      </a>
    </nav>
  )
};

export { SimpleNav };
export default SimpleNav;