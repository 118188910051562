import { storage } from '..';
import { constants, DateRange } from '../../utils';
import {
  AnalyticsRequest,
  GlobalRequest,
  AnalyticsResult,
  AnalyticsScorecard,
  MetricType,
  TypeItemsSelect,
  IntervalType,
  TrendRequest,
  AnalyticsTrendResult,
  GroupedSegmentResult,
  AnalyticsTrendChart,
  OverviewResult,
  PerformanceReportRequest,
  AnalyticsServerResponse,
  InvitationPerformanceReport,
  Filter
} from './analytics.models';
import { FilterOptions } from '../../store/slices/filters';
import moment from 'moment';

const ONE_FIVE_SCALE_QUESTIONS = [
  'answer.ai_ces', 'answer.ai_response_time', 'answer.ai_interest_level', 'answer.ai_info_clarity', 'answer.ai_agent_knowledge', 'answer.ai_agent_professionalism',
  'answer.ad_ces', 'answer.ad_agent_follow_up',
  'answer.ap_agent_follow_up',
  'answer.aa_agent_follow_up',
  'answer.ea_contact_ces', 'answer.ea_response_time', 'answer.ea_last_experience_score', 'answer.ea_interest_level',
  'answer.ee_last_experience_score', 'answer.ee_agent_follow_up', 'answer.ee_interest_level', 'answer.ee_agree_level',
  'answer.em_last_experience_score', 'answer.em_agent_follow_up', 'answer.em_response_time',
  'answer.ek_agent_follow_up', 'answer.ek_response_time',
];

/* 
 * /analytics/graphics/score-segments
 */
async function getAnalyticsResult (
  studyId: string,
  range: DateRange,
  metricType: MetricType,
  fieldMetric: string,
  dateRangeField = 'createdAt',
  filters?: FilterOptions[],
  fieldSegment?: string,
  byExistence?: boolean,
  fieldsGroup?: string[],
  previousTimeInterval?: DateRange,
): Promise<AnalyticsResult> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: AnalyticsRequest = {
    studyId,
    typeMetric: metricType,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
  };
  if (fieldSegment) {
    body.fieldSegment = fieldSegment;
  }
  if (byExistence) {
    body.byExistence = true;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  if (fieldsGroup) {
    body.fieldsGroup = fieldsGroup;
  }
  if (previousTimeInterval) {
    body.previousTimeInterval = {
      field: dateRangeField,
      start: moment(previousTimeInterval.start).startOf('day').toISOString(),
      end: moment(previousTimeInterval.end).endOf('day').toISOString(),
    }
  }
  if (metricType === MetricType.COUNT && ONE_FIVE_SCALE_QUESTIONS.includes(fieldMetric)) {
    body.setsId = '60f200bc7ae816f487844341';
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/graphics/score-segments`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsResult = await fetchResponse.json();
  return response;
}

/* 
 * /analytics/graphics/score-mailing-report
 */
async function getPerformanceReport (
  studyId: string,
  range: DateRange,
  dateRangeField = 'createdAt',
  filters?: Filter[]
): Promise<InvitationPerformanceReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: PerformanceReportRequest = {
    studyId,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
  };
  if (filters && filters.length > 0) {
    body.filters = filters
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/performance/interactions-report`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsServerResponse<InvitationPerformanceReport> = await fetchResponse.json();
  return response.report;
}

// Solo conteo y promedio
async function getAnalyticsScorecard (
  studyId: string,
  range: DateRange,
  metricType: MetricType,
  fieldMetric: string,
  dateRangeField = 'createdAt',
  filters?: FilterOptions[],
  fieldSegment?: string,
  byExistence?: boolean,
): Promise<AnalyticsScorecard> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: AnalyticsRequest = {
    studyId,
    typeMetric: metricType,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
  };
  if (fieldSegment) {
    body.fieldSegment = fieldSegment;
  }
  if (byExistence) {
    body.byExistence = true;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/graphics/score-cards`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsScorecard = await fetchResponse.json();
  return response;
}

// Tendencia
export interface TrendOptions {
  studyId: string;
  range: DateRange;
  dateRangeField: string;
  typeInterval: IntervalType;
  metricType: MetricType;
  fieldMetric: string;
  fieldSegment: string;
  filters?: FilterOptions[];
  byExistence?: boolean;
  grouper?: string;
}

async function getTrendResult (options: TrendOptions): Promise<AnalyticsTrendResult> {

  const {
    studyId,
    range,
    dateRangeField,
    typeInterval,
    metricType,
    fieldMetric,
    fieldSegment,
    filters,
    byExistence,
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let URL = `${constants.API_URL}/analytics/graphics/score-interval`;
  let body: TrendRequest = {
    studyId,
    typeMetric: metricType,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
    typeInterval,
    fieldSegment,
  };
  if (byExistence) {
    body.byExistence = true;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsTrendResult = await fetchResponse.json();
  return response;
}

// Tendencia agrupada
async function getGroupedTrendResult (options: TrendOptions): Promise<AnalyticsTrendChart[]> {

  const {
    studyId,
    range,
    dateRangeField,
    typeInterval,
    metricType,
    fieldMetric,
    fieldSegment,
    filters,
    byExistence,
    grouper,
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let URL = `${constants.API_URL}/analytics/graphics/score-grouped-interval`;
  let body: TrendRequest = {
    studyId,
    typeMetric: metricType,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
    typeInterval,
    fieldSegment,
  };
  if (byExistence) {
    body.byExistence = true;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  if (grouper) {
    body.grouper = grouper;
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsTrendChart[] = await fetchResponse.json();
  return response;
}

// Agrupado
export interface GroupedSegmentOptions {
  studyId: string;
  range: DateRange;
  dateRangeField: string;
  metricType: MetricType;
  fieldMetric: string;
  fieldToGroup: string;
  fieldSegment?: string;
  filters?: FilterOptions[];
}

async function getGroupedSegmentResult (options: GroupedSegmentOptions): Promise<GroupedSegmentResult> {

  const {
    studyId,
    range,
    dateRangeField = 'createdAt',
    metricType,
    fieldMetric,
    fieldToGroup,
    fieldSegment,
    filters,
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: AnalyticsRequest = {
    studyId,
    typeMetric: metricType,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
    fieldToGroup,
  };
  if (fieldSegment) {
    body.fieldSegment = fieldSegment;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/graphics/score-segments-grouped`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: GroupedSegmentResult = await fetchResponse.json();
  return response;
}

// Overview
export interface OverviewOptions {
  studyId: string;
  range: DateRange;
  compareRange?: DateRange;
  dateRangeField: string;
  metricType: MetricType;
  selectItemsBy: TypeItemsSelect;
  fieldMetric: string;
  fieldSegment: string;
  groupBy: string;
  filters?: FilterOptions[];
}

async function getOverviewResult (options: OverviewOptions): Promise<OverviewResult> {

  const {
    studyId,
    range,
    compareRange,
    dateRangeField = 'createdAt',
    metricType,
    selectItemsBy,
    fieldMetric,
    fieldSegment,
    groupBy,
    filters,
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: GlobalRequest = {
    studyId,
    typeMetric: metricType,
    selectItemsBy: selectItemsBy,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    fieldMetric,
    fieldSegment,
    fieldToGroup: groupBy,
  };
  if (fieldSegment) {
    body.fieldSegment = fieldSegment;
  }
  if (filters) {
    body.filters = filters.map(f => ({
      field: f.field,
      values: f.values.map(v => v.value),
    }));
  }
  if (compareRange?.start && compareRange?.end) {
    body.previousTimeInterval = {
      field: dateRangeField,
      start: compareRange.start.startOf('day').toISOString(),
      end: compareRange.end.endOf('day').toISOString(),
    }
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/graphics/score-segments-grouped-global`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: OverviewResult = await fetchResponse.json();
  return response;
}

export {
  getAnalyticsResult,
  getAnalyticsScorecard,
  getPerformanceReport,
  getTrendResult,
  getGroupedSegmentResult,
  getGroupedTrendResult,
  getOverviewResult,
}