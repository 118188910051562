import React, { CSSProperties, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectFiltersSlice, showFilterBar } from '../../../store/slices/filters';
import { FilterIcon } from '../../icons/filter';

const FilterButtonStyle: CSSProperties = {
  backgroundColor: '#0388D1',
  color: '#FFFFFF',
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 1,
  borderRadius: 8,
  marginRight: 16,
  padding: 16,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  width: 160,
  height: 40,
}

const FilterButton: FC = () => {

  const filters = useAppSelector(selectFiltersSlice);
  const dispatch = useAppDispatch();
  const isSingular = filters.active.length === 1;

  return (
    <div style={FilterButtonStyle} onClick={() => dispatch(showFilterBar())}>
      <span>Filtrar ({`${filters.active.length} activo${isSingular ? '' : 's'}`})</span>
      <FilterIcon />
    </div>
  );

}

export {
  FilterButton
}