import React, { FC, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { auth } from '../../../services'
import { TextInput } from '../../../common/components';
import { validate } from '../../../utils';

type NewPassFormProps = {

};

const NewPassForm: FC<NewPassFormProps> = (

) => {
  const [password, setPassword] = useState('');
  const location = useLocation();
  const history = useHistory();

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    setPassword(newValue);
  }

  function updatePass (e: React.FormEvent) {
    e.preventDefault();
    try {
      validate('password', password);
      auth.user.completeNewPasswordChallenge(password, {}, {
        onSuccess: data => {
          console.log('New pass success: ', data);
          history.push('/start');
        },
        onFailure: () => {},
      })
    } catch (err) {

    }
  }

  return (
    <form style={{ width: '100%' }} noValidate onSubmit={updatePass}>
      <p className="auth-guide">{location.state?.guideMessage || 'Ingresa tu nueva contraseña.'}</p>
      <TextInput
        label="Nueva contraseña"
        fieldName="password"
        type="password"
        value={password}
        onChange={onValueChange}
      />
      <input type="submit" value="Actualizar contraseña" className="form-submit-button w-button" />
    </form>
  );
}

export default NewPassForm;
export {
  NewPassForm,
};