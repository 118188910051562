import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components'
import { ExportsTable } from './widgets';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';

interface ExportScreenProps {
};

const ExportScreen: FC<ExportScreenProps> = () => {
  const history = useHistory();
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();

  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);

  function goToNewExport () {
    return history.push('/new-export')
  }

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title={viewConfig.viewOptions.pageTitle}
              actionLabel="Nueva exportación"
              action={goToNewExport}
              showFilters={false}
              showMainFilters={viewConfig.viewOptions.showMainFilters}
            />
            <ExportsTable />
          </>
        ) : (
          <div>Ocurrió un problema al cargar las exportaciones de datos.</div>
        )}
    </MainLayout>
  );
}

export default ExportScreen;
export {
    ExportScreen,
};