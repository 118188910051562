import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import useNpsBreakdown, { NpsBreakdownConfig } from '../../../common/hooks/use-nps-breakdown'
import BaseTable, { BaseTableProps } from '../../../common/widgets/table.w'
import { Tooltip } from '@material-ui/core'
import { Collapsible } from '../../../common/components/collapsible'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { LocalizedText, SupportedLanguages } from '../../../services/filters'
import { ReportConfig } from '../../../services/views'

export type ColFormat = 'percentage' | 'number';

export type NpsBreakdownReportTableOptions = {
  varsTitle: LocalizedText,
  collapsible: boolean,
  colsToShow: Array<{
    field: string,
    hide: boolean,
    display: LocalizedText,
    format: ColFormat,
  }>
}

export type NpsBreakdownReportTableProps = ReportConfig<
  NpsBreakdownConfig,
  NpsBreakdownReportTableOptions
> & {
  onRowClick?: BaseTableProps['onRowClick']
}

const makeGetDisplay = (field: string, language: SupportedLanguages): GridColDef['valueGetter'] =>
  (params: GridValueGetterParams) => params.row[field]?.[language] ?? '-'

const makeGetNested = (field: string): GridColDef['valueGetter'] => {
  return (params: GridValueGetterParams) => _.get(params.row, field)
}

const formatters: Record<ColFormat, GridColDef['valueFormatter']> = {
  percentage: (p) => `${p.value}%`,
  number: (p) => p.value,
}

const baseColProps: Partial<GridColDef> = {
  minWidth: 144,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true,
}

const NpsBreakdownReportTable = ({
  title = { default: '', es: '' },
  query,
  options: { colsToShow = [], varsTitle, collapsible = false },
  onRowClick,
}: NpsBreakdownReportTableProps) => {
  const [loadReport, loading, error, segmentedReport] = useNpsBreakdown(query)
  const [tableCols, setTableCols] = useState<GridColDef[]>([])
  const [data, setData] = useState<Array<any>>([])
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = [
    {
      field: 'display',
      type: 'string',
      headerName: varsTitle[preferredLang],
      disableColumnMenu: true,
      width: 320,
      valueGetter: makeGetDisplay('display', preferredLang),
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ value }: GridCellParams) => (
        <Tooltip title={value || ''}>
          <span>{value}</span>
        </Tooltip>
      ),
      sortable: false,
    },
  ]
  const actionCols: GridColDef[] = !onRowClick ? [] : [{
    field: 'action',
    headerName: 'Acciones',
    width: 180,
    sortable: false,
    renderCell: (params) => (
      <button className="table-link-button">
        Ver detalle
      </button>
    )
  }]
  const getCols = () => {
    return cols.concat(colsToShow.map(c => ({
      ...baseColProps,
      field: c.field,
      valueGetter: makeGetNested(c.field),
      hide: c.hide,
      headerName: c.display[preferredLang],
      valueFormatter: formatters[c.format],
    }))).concat(actionCols)
  }

  useEffect(() => {
    loadReport()
  }, [loadReport])

  useEffect(() => {
    if (!loading && !error && segmentedReport) {
      const extendedCols: GridColDef[] = getCols()
      setTableCols(extendedCols)
      setData(segmentedReport.map(r => ({ distinctId: r.display.default, ...r })))
    }
  }, [loading, error, segmentedReport])

  return (
    <div className="span-two-cols">
      <Collapsible collapsible={collapsible} initialHeight={44}>
        <BaseTable
          rowIdKey="distinctId"
          rows={data}
          columns={tableCols}
          loading={loading}
          onReload={loadReport}
          rowHeight={32}
          hideFooter
          autoHeight
          tableTitle={title[preferredLang]}
          downloadFileName={title[preferredLang]}
          onRowClick={onRowClick}
          componentsProps={{
            toolbar: {
              csvOptions: {
                //allColumns: true,
              },
            },
          }}
          localeText={{
            toolbarFilters: '',
            toolbarExport: '',
            toolbarColumns: 'Columnas',
            noRowsLabel: 'No se encontraron registros.',
            toolbarExportCSV: 'Descargar CSV',
            toolbarExportLabel: 'Exportar',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filtros',
          }}
        />
      </Collapsible>
    </div>
  )
}

export {
  NpsBreakdownReportTable,
}
