import { useEffect, useState } from 'react'
import { CalcParams } from '../../../services/reports'
import { LocalizedText } from '../../../services/filters'
import './kpi-summary.w.css'
import { ReportConfig } from '../../../services/views'
import useKpiSummary from '../../../common/hooks/use-kpi-summary'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { Tooltip } from '@material-ui/core'

export type KpiCountTableConfig = ReportConfig<
  {
    dateRangeField: string,
    field: string,
    calc: CalcParams
  },
  {
    resultDisplay: LocalizedText,
    compareWithPreviosPeriod: boolean
  }
>

const KpiCountTable = ({
  query,
  options: {
    resultDisplay,
    compareWithPreviosPeriod
  },
}: KpiCountTableConfig) => {
  const [loadReport, loading, error, kpiCountTable] = useKpiSummary(Object.assign(query, {compareWithPreviosPeriod: compareWithPreviosPeriod !== undefined ? compareWithPreviosPeriod : true }));
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && kpiCountTable) {
      setData(kpiCountTable.report.detail);
    }
  }, [loading, error, kpiCountTable]);

  const cols: GridColDef[] = [
    {
      field: 'distinctId',
      type: 'string',
      headerName: 'Comentario',
      disableColumnMenu: true,
      flex: 0.5,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ value }: GridCellParams) => (
        <Tooltip title={value || ''}>
          <span>{value}</span>
        </Tooltip>
      ),
      sortable: false,
    },
  ];

  return (
    <div style={{ flex: 1, borderRadius: 14, padding: '16px 0' }}>
     <BaseTable
          rowIdKey="distinctId"
          rows={data}
          columns={cols}
          loading={loading}
          onReload={loadReport}
          rowHeight={32}
          style={{minHeight: '300px', maxHeight:'500px' }}
          tableTitle={resultDisplay.es}
          disableSelectionOnClick
          disableDensitySelector
          disableColumnSelector
          disableColumnFilter
          autoPageSize
          localeText={{
            toolbarFilters: '',
            toolbarExport: '',
            toolbarColumns: 'Columnas',
            noRowsLabel: 'No se encontraron registros.',
            toolbarExportCSV: 'Descargar CSV',
            toolbarExportLabel: 'Exportar',
            MuiTablePagination: {
              labelRowsPerPage: 'Comentarios por página:',
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
            }
          }}
        />
    </div>
  )
}

export {
  KpiCountTable
}