import React, { FC } from 'react';

const FilterIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="7" viewBox="0 0 10.5 7">
    <g id="filter-results-button" transform="translate(0 -76.5)">
      <g id="filter" transform="translate(0 76.5)">
        <path d="M4.083,83.5H6.417V82.333H4.083ZM0,76.5v1.167H10.5V76.5Zm1.75,4.083h7V79.417h-7Z" transform="translate(0 -76.5)" fill="#fff"/>
      </g>
    </g>
  </svg>
);

export {
  FilterIcon
};