import React, { FC, Key } from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { AuthLayout } from '../../common/layouts';
import { LoginForm, NewPassForm, RecoverPassForm } from './widgets';

type AuthScreenProps = {

};

const authRoutes: RouteProps[] = [
  {
    path: '/login',
    component: LoginForm,
    // exact: true,
  },
  {
    path: '/recover-password',
    component: RecoverPassForm,
    // exact: true,
  },
  {
    path: '/new-password',
    component: NewPassForm,
    // exact: true,
  },
]

const AuthScreen: FC<AuthScreenProps> = (
  
) => {
  let { path: basePath } = useRouteMatch()

  return (
    <AuthLayout
      title=""
    >
      <Switch>
        {authRoutes.map(routeConfig => (
          <Route key={routeConfig.path as Key} {...routeConfig} path={`${basePath}${routeConfig.path}`} />
        ))}
      </Switch>
    </AuthLayout>
  );
}

export default AuthScreen;
export {
  AuthScreen,
};