import React, { FC, useCallback, useEffect, useState } from 'react';
import ReactTags from 'react-tag-autocomplete'
import { updateTags } from '../../../services/comments';
import { matchSorter } from "match-sorter";

const TagEditable: FC<any> = ({
  tag,
  suggestions,
  id,
  answerId,
  tagName,
  requiresVerification
}) => {
  const init:any[] = []
  const [tags, setTags] = useState(init)
  const [selectedTag, setSelectedTag] = useState(tag)
  const [originalTag, setOriginaltag] = useState(tagName)
  const [verification, setVerification] = useState(false)
  const [type, setType] = useState('hidden')
  const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [errors, setErrors] = useState(false)

  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex))
    setType('text')
    setTimeout ( function timeoutFunction () {
      document.getElementById(id)?.focus()
    }, 50);
  }, [tags])
  
  const onAddition = useCallback(async (newTag) => {
    setLoading(true)
    setTags([...tags, newTag])
    setType('hidden')
    setSelectedTag(newTag)
    setVerification(false)
    try {
      const res = await updateTags(id, answerId, newTag.id)
      setLoading(false)
      if(res){
        setUpdated(true)
        setTimeout ( function timeoutFunction () {
          setUpdated(false)
        }, 5000);
      } else {
        setErrors(true)
        setTimeout ( function timeoutFunction () {
          setErrors(false)
        }, 5000);
      }
    } catch (error) {
      setLoading(false)
      setErrors(true)
      setTimeout ( function timeoutFunction () {
        setErrors(false)
      }, 5000);
    }
    
  }, [tags])

  const onBlur = useCallback(() => {
    if(selectedTag) {
      setType('hidden')
      setTags([selectedTag])
    } else{
      setType('text')
    } 
  }, [tags])

  useEffect(() => {
    if(tag && tag !== '') {
      setType('hidden')
      setTags([tag])
    } else{
      setType('text')
    } 
    if(requiresVerification === 'true') setVerification(true)
  }, []);

  function suggestionsFilter(query: any, suggestions:any): any {
    return matchSorter(suggestions, query, { keys: ['id','name'] });
  }

  return (
    <div className="comment-meta">
       <ReactTags
        tags={tags}
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
        onBlur={onBlur}
        suggestionsTransform={suggestionsFilter}
        noSuggestionsText='Motivo no registrado'
        placeholderText='Buscar motivo'
        inputAttributes={{'type': type, 'id': id}}
        classNames={{
          root: 'react-tags',
          rootFocused: 'is-focused',
          selected: 'react-tags__selected',
          selectedTag: verification ? 'react-tags__selected-tag-verification' : 'react-tags__selected-tag',
          selectedTagName: 'react-tags__selected-tag-name',
          search: 'react-tags__search',
          searchInput: 'react-tags__search-input',
          suggestions: 'react-tags__suggestions',
          suggestionActive: 'is-active',
          suggestionDisabled: 'is-disabled',
        }}
      />
      {(!selectedTag && originalTag) && <div className='comment-tag-not-found'>{originalTag}</div>}
      {loading && <span className="loader"></span>}
      {updated &&<span className="checkmark">
        <div className="checkmark_stem"></div>
        <div className="checkmark_kick"></div>
      </span>}
      {errors && <span className='errorComment'>Error al actualizar</span>}
    </div>
  );
}

export {
  TagEditable,
}