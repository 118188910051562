import React, { FC } from 'react';
import { Navbar } from '../components';
import { FilterBar } from '../components/filters';

interface MainLayoutProps {
};

const MainLayout: FC<MainLayoutProps> = ({
  children
}) => {
  return (
    <div className="screen">
      <Navbar showControls showMenu />
      <FilterBar />
      <main className="main">
        <div className="container">
          {children}
        </div>
      </main>
    </div>
  );
}

export default MainLayout;
export {
    MainLayout
};