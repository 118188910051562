export enum MetricType {
  COUNT = 'CT',
  TOP_BOX = 'TOP_BOX',
  TOP_2_BOXES = 'TOP_2_BOXES',
  BOTTOM_BOX = 'BOTTOM_BOX',
  BOTTOM_2_BOXES = 'BOTTOM_2_BOXES',
  AVERAGE = 'AVG',
  CES = 'CES', // %Top menos %Bottom 1 - 5
  NPS = 'NPS', // %Top menos %Bottom 1 - 10
}

export enum TypeItemsSelect {
  SETS = 'sets',
  DISTINCT = 'distinct',
  PARAMETERS = 'parameters'
}

export interface AnalyticsDateRange {
  field: string;
  start: string;
  end: string;
}

export interface Filter {
  field: string;
  values: string[] | number[];
}
export interface AnalyticsRequest {
  studyId: string;
  dateRange: AnalyticsDateRange;
  previousTimeInterval?: AnalyticsDateRange;
  fieldMetric: string;
  typeMetric: MetricType;
  filters?: Filter[];
  fieldSegment?: string;
  byExistence?: boolean;
  setsId?: string;
  fieldsGroup?: string[];
  fieldToGroup?: string;
}

export interface PerformanceReportRequest {
  studyId: string;
  dateRange: AnalyticsDateRange;
  filters?: Filter[];
}

export interface TrendRequest extends AnalyticsRequest {
  typeInterval: IntervalType;
  grouper?: string;
}

export interface GlobalRequest extends AnalyticsRequest {
  selectItemsBy: TypeItemsSelect;
}

export interface AnalyticsScorecard {
  n: number;
  nPrevious: number;
  result: number;
  previousResult: number;
  difference: number;
  percentageDifference: number;
  percentage?: number;
}

export interface AnalyticsChart {
  value: {
    value: string;
    display: string;
  };
  n: number;
  nPrevious: number;
  result: number;
  resultPrevious: number;
  difference: number;
  percentageDifference: number;
  percentage: number;
}

export interface AnalyticsResult {
  n: number;
  nPrevious: number;
  ces?: number,
  nps?: number,
  result?: number;
  resultPrevious?: number;
  difference: number;
  percentageDifference: number;
  chart: AnalyticsChart[];
}

export interface AnalyticsReportMailingChart {
  n: number;
  name: string;
  sendDate?:number;
  sendDate_percentage?: string;
  openAt?: number;
  openAt_percentage?: string;
  clickAt?: number;
  clickAt_percentage?: string;
  answers: number;
  answers_percentage: string;
  started: number;
  started_percentage: string;
  leave: number;
  leave_percentage: string;
  completed: number;
  completed_percentage: string;
}

export interface  ReportMailingChart {
  n: number;
  channel: string;
  chart: AnalyticsReportMailingChart[]
}

export type AnalyticsServerResponse<T> = {
  message: string;
  report: T
}

enum ChannelT {
  'email' = 'email',
  'sms' = 'sms'
}

export interface InvititationPerformance {
  type: string;
  channel: ChannelT;
  interactions: number;
  invitationsSend: number;
  totalOpen: number;
  invitationsSendPercentage: number;
  invitationsOpen: number;
  invitationsOpenPercentage: number;
  answers: number;
  answersPercentage: number;
  invitationsStarted: number;
  totalOpenPercentage:number; 
  invitationsStartedPercentage: number;
  answersLeave: number;
  answersLeavePercentage: number;
  answersCompleted: number;
  answersCompletedPercentage: number;
}

export type InvitationPerformanceReport = {
  n: number;
  chart: Array<InvititationPerformance>
}

export type LightChart = Omit<AnalyticsChart, 'nPrevious' | 'resultPrevious'| 'difference' | 'percentageDifference' >;

export interface NestedChart {
  n: number;
  value: string;
  chart: LightChart[];
}

export interface GroupedSegmentResult {
  n: number;
  chart: NestedChart[];
}


export interface AnalyticsTrendChart {
  n: number;
  result: number;
  value: {
    value: string;
    display: string;
  };
  chart: LightChart[];
}
export interface AnalyticsTrendResult {
  n: number;
  chart: AnalyticsTrendChart[];
}

export interface OverviewChart {
  value: string;
  n: number;
  nPrevious?: number;
  avg?: number;
  avgPrevious?: number;
  avgDifference?: number;
  avgDifferencePercentages?: number;
  chartMetric: AnalyticsChart[];
  chartCount: AnalyticsChart[];
  [x:string]: any;
}

export interface OverviewResult {
  global: OverviewChart;
  chart: OverviewChart[];
}

export enum IntervalType {
  DAY = 'day',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  HOUR = 'hour'
}

export type AnalyticsResultProp = keyof Omit<AnalyticsResult, 'chart'>;

export interface ChartDisplayOption {
  display: string;
  color?: string;
}

export interface IndexedOptions {
  [k: string]: ChartDisplayOption
}